// extracted by mini-css-extract-plugin
export var ArtistDescription = "AnaMonso-module--ArtistDescription--5aXdN";
export var ArtistInfos = "AnaMonso-module--ArtistInfos--y78mx";
export var ButtonWrapper = "AnaMonso-module--ButtonWrapper --PlSwQ";
export var CardWrapper = "AnaMonso-module--CardWrapper---HQw0";
export var CarrouselWrapper2 = "AnaMonso-module--CarrouselWrapper2--K01MO";
export var Citations = "AnaMonso-module--Citations--GwqJX";
export var DescriptionWrapper = "AnaMonso-module--DescriptionWrapper--+WKam";
export var ImageWrapper = "AnaMonso-module--ImageWrapper--Z4FO9";
export var LinkWrapper = "AnaMonso-module--LinkWrapper--pKipt";
export var MobileProtrait = "AnaMonso-module--MobileProtrait--MpO3T";
export var More = "AnaMonso-module--More--zAbUX";
export var MoreButton = "AnaMonso-module--MoreButton--gxus+";
export var NameWrapper = "AnaMonso-module--NameWrapper--Uhxip";
export var PdpWrapper = "AnaMonso-module--PdpWrapper--zFtQi";
export var PhotosWrapper = "AnaMonso-module--PhotosWrapper--AA5Gg";
export var ProfilWrapper = "AnaMonso-module--ProfilWrapper--KSIoV";
export var TitleWrapper = "AnaMonso-module--TitleWrapper--bUD0i";
export var Wrapper = "AnaMonso-module--Wrapper--Fj3X+";